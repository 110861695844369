<template>
  <div class="restaurant-wraper">
    <app-modal-confirm
      ref="restaurant_delete_modal"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteRestaurant"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
    <validation-observer
      ref="entry"
      v-slot="{ passes, valid, validated }"
    >
    <form class="form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
      <div class="card card-body">
          <div v-if="idEditing">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-1 col-lg-1 col-sm-1 p-t-2 align-self-center"> {{ entry.id }}</div>
              <div class="col-xl-4 col-lg-5 col-md-6 col-sm-4">
                <button
                  @click="handleOpenQRCode(entry.id)"
                  class="btn btn-primary btn-qrcode"
                  type="button"
                  style="height: 40px !important;"
                >
                  {{ 'QRコードを別ウインドウで表示する' }}
                </button>
              </div>
            </div>
          </div>
          <validation-provider
            name="status_enabled"
            rules="required"
            v-slot="{ errors, invalid, validated }"
          >
              <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurants.status_enabled") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6 mt-2">
              <app-radio v-for="(item, index) in RESTAURANTS_CONSTANT.STATUS"
                         :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                         :val="item.id" v-model="entry.status_enabled"/>
              <label v-if="invalid && validated" class="error">
                {{ errors[0] }}
              </label>
            </div>
          </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurants.mansion") }} <span
              class="app-require">＊</span></label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurants.facility_name") }} <span
              class="app-require">＊</span></label>
            <div class="col-xl-5 col-lg-6 col-sm-6" v-if="idEditing">
              <app-select
                name="facility_id"
                rules="required"
                :options-data="meta.facilities"
                v-model="entry.facility_id"
              />
            </div>
            <div class="col-xl-5 col-lg-6 col-sm-6" v-else>
              <app-select
                name="facility_id"
                rules="required"
                :options-data="meta.facilities"
                v-model="entry.facility_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurants.table_name") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="テーブル名"
                rules="required"
                v-model="entry.name"
                type="string128"
              />
            </div>
          </div>
          <div class="row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label p-0"></label>
            <label class="col-xl-5 col-lg-6 col-sm-6 notifi-resetpass">館内施設を選択・変更するとそのIDが店舗IDとして設定されます。</label>
          </div>
          <div class="form-group row restaurant-text">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label p-0"></label>
            <label class="col-xl-5 col-lg-6 col-sm-6 notifi-resetpass">ただし、任意のユニークな文字列に変更することも可能です。</label>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurants.note") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-text
                name="note"
                v-model="entry.note"
                type="string1024"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-9 col-lg-9 col-md-10">
              <h3 class="title-h3">スマレジWaiter 連携設定</h3>
              <label class="notifi-resetpass">*** 以下の連携設定は、スマレジWaiterの管理画面で店舗とテーブルを作成してから行ってください ***</label>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurants.smaregi_store_id") }}
              <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-select
                name="smaregi_store_id"
                :options-data="meta.smaregiStores"
                v-model="entry.smaregi_store_id"
                rules="required"
                @change="getStoreTable(entry.smaregi_store_id)"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurants.smaregi_table_id") }}
              <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-select
                name="smaregi_table_id"
                rules="required"
                :options-data="meta.smaregiStoreTables"
                v-model="entry.smaregi_table_id"
              />
            </div>
          </div>
        <div class="card-footer row justify-content-end gr-button">
          <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="handlerCancelButton">
            {{ $t('common.cancel') }}
          </button>
          <button type="button" :disabled="!valid && validated" class="btn btn btn-danger m-r-10" @click="deleteRestaurantConfirm" v-if="idEditing">
            {{ $t('apartments.delete') }}
          </button>
          <button class="btn btn-primary" :disabled="!valid && validated" type="button" @click="createOrUpdate">
            {{idEditing ?  $t('common.save') :  $t('common.create')}}
          </button>
        </div>
      </div>
    </form>
    </validation-observer>
  </div>
</template>

<script>
  import AppModal from "../../../components/_common/popup/AppModal";
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppModalCustomer from "@components/_common/popup/AppModalCustomer";
  import AppCheckbox from "../../../components/forms/AppCheckbox.vue";
  import AppTextarea from "../../../components/forms/AppTextarea";
  import AppModalConfirm from "@components/_common/popup/AppModalConfirm";
  import { BASE_URL } from "@constants/api";
  import {cloneDeep} from "lodash";
  import {ROUTES} from "@constants";


  export default {
    name: "FormPage",

    components: {
      AppTextarea,
      AppModal,
      "app-basic-table": AppBasicTable,
      AppModalCustomer,
      AppModalConfirm,
      "app-checkbox": AppCheckbox,
    },

    data() {
      const id = this.$route.params.id
      return {
        entry : {
          status_enabled : 1,
          smaregi_store_id: "",
          smaregi_table_id: "",
          smaregi_store_name: ""
        },
        idEditing: id,
        meta: {
          apartments: [],
          facilities: [],
          smaregiStores: [],
          smaregiStoreTables: [],
        },
      };
    },

    watch: {
    'entry.apartment_id': function(value, oldValue) {
      if (value != oldValue && value != '') {
        this.getFacilityList(value)
      }
      if (value != oldValue && value == '') {
        this.resetValue(value)
      }
    }
  },

    mounted: async function () {
      await this.$request.get(this.ENDPOINT.UI_HELPER_RESTAURANTS_SMAREGI, {status: 'active'}).then(res => {
        this.meta.smaregiStores = res.data.smaregiStores;
        this.meta.smaregiStoreTables = res.data.smaregiStoreTables;
        let objChooseSelect = {
          id: "",
          name: "--選択--"
        };
        this.meta.smaregiStores.unshift(objChooseSelect);
        this.meta.smaregiStoreTables.unshift(objChooseSelect);
      })
      await this.$request.get(this.ENDPOINT.UI_HELPER_FACILITIES).then(res => {
        this.meta.apartments = res.data.apartments;
        this.meta.facilities = res.data.facilities;
        this.meta.apartments.unshift({
          id: "",
          name: "--選択--"
        })
        this.meta.facilities.unshift({
          id: "",
          name: "--選択--"
        })
      })

      if (this.idEditing) {
        await this.$request.get(this.ENDPOINT.RESTAURANT_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue()
          } else {
            this.$request.get(this.ENDPOINT.UI_HELPER_RESTAURANTS_SMAREGI, {
              smaregi_store_id: this.entry.smaregi_store_id,
              status: 'all'
            })
            this.entry = res.data.data
          }
        })
      }
    },

    methods: {
      deleteRestaurantConfirm() {
        this.$refs.restaurant_delete_modal.show();
      },
      handleOpenQRCode(entry_id) {
        window.open( `/restaurants/qr_code_table/${entry_id}`, '_blank')
      },
      async deleteRestaurant(){
        const res = await this.$request.delete(this.ENDPOINT.RESTAURANT_DELETE(this.idEditing));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          await this.redirectDefaultValue();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.restaurant_delete_modal.hide();
        }
      },
      async createOrUpdate() {
        if (!await this.$refs.entry.validate()) {
          return;
        }
        let res = null;
        let msg = "";
        let errTypeMsg = "";
        let check_facility = await this.$request.get(this.ENDPOINT.UI_HELPER_RESTAURANTS_FACILITY(this.entry.facility_id))
        if (!this.idEditing) {
          if(check_facility.data.facilities.length !== 0 && check_facility.data.facilities[0].biz_type !== 0){
            errTypeMsg = this.$t("facilities.biz_type_err")
          } else {
            this.entry.smaregi_store_name = this.entry.smaregi_store_name.split(' (ID')[0]
            res = await this.$request.post(this.ENDPOINT.RESTAURANT_CREATE, this.entry);
            msg = this.$t("common.msg create ok")
          }
        } else {
          if (check_facility.data.facilities.length !== 0) {
            if (check_facility.data.facilities[0].biz_type === 0) {
              this.entry.smaregi_store_name = this.entry.smaregi_store_name.split(' (ID')[0]
              res = await this.$request.patch(this.ENDPOINT.RESTAURANT_EDIT(this.idEditing), this.entry);
              msg = this.$t("common.msg update ok")
            } else {
              errTypeMsg = this.$t("facilities.biz_type_err")
            }
          }
        }
        if (res && res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            await this.redirectDefaultValue()
          }
        } else if (this.idEditing && check_facility.data.facilities.length === 0){
          this.__noticeError(this.$t('restaurants.error_update'));
        } else if (errTypeMsg) {
          this.__noticeError(errTypeMsg);
        }  else {
          this.__noticeSuccess(msg);
          await this.redirectDefaultValue();
        }
      },
      async getFacilityList(apartment_id) {
        if(apartment_id) {
          await this.$request.get(this.ENDPOINT.UI_HELPER_RESTAURANTS_FACILITY_LIST(apartment_id)).then(res => {
            let facilities = res.data.facilities;
            if(facilities.length !== 0) {
              this.meta.facilities = cloneDeep(res.data.facilities);
              this.meta.facilities.unshift({id: null, name: this.$t('common.choose')});
            } else {
              this.$request.get(this.ENDPOINT.UI_HELPER_FACILITIES).then(res => {
                this.meta.apartments = res.data.apartments;
                this.meta.facilities = res.data.facilities;
                this.meta.apartments.unshift({
                  id: "",
                  name: "--選択--"
                })
                this.meta.facilities.unshift({
                  id: "",
                  name: "--選択--"
                })
              })
            }
          })
        }
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.RESTAURANTS_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      async redirectDefaultValue() {
        await this.$router.push({
          name: this.ROUTES.ADMIN.RESTAURANTS_LIST,
          query : {
            'filters.status_enabled.equal': 'all',
            'filters.apartment_id.equal' : 'all',
            'filters.facility_id.equal' : 'all'
          }
        });
      },
      redirectBack() {
        this.$router.go(-1);
      },
      async resetValue() {
        if(this.entry.apartment_id == '') {
          await this.$request.get(this.ENDPOINT.UI_HELPER_FACILITIES).then(res => {
            this.meta.apartments = res.data.apartments;
            this.meta.facilities = res.data.facilities;
            this.meta.apartments.unshift({
              id: "",
              name: "--選択--"
            })
            this.meta.facilities.unshift({
              id: "",
              name: "--選択--"
            })
          })
        }
      },
      getStoreTable(smaregiStoreId) {
        this.$request.get(this.ENDPOINT.UI_HELPER_RESTAURANTS_SMAREGI, {
          smaregi_store_id: smaregiStoreId,
          status: 'all'
        }).then(res => {
          this.meta.smaregiStores = res.data.smaregiStores;
          this.meta.smaregiStoreTables = res.data.smaregiStoreTables;
          let objChooseSelect = {
            id: "",
            name: "--選択--"
          };
          let smaregiStoreSelected = this.meta.smaregiStores.find(elem => elem.id == smaregiStoreId);
          if (smaregiStoreSelected) {
            this.entry.smaregi_store_name = smaregiStoreSelected.name;
          }
          this.meta.smaregiStores.unshift(objChooseSelect);
          this.meta.smaregiStoreTables.unshift(objChooseSelect);
        })
      }
    },
  };
</script>
<style>
  .restaurant-text {
    height: 22px;
  }
  .restaurant-wraper h3.title-h3 {
    border: 1px solid #9ba2aa;
    text-align: center;
    font-size: 23px;
    padding: 8px;
    margin-bottom: 20px;
    font-weight: 600;
    margin-top: 20px;
    margin-right: 27.4%;
  }
  @media (min-width: 1621px) {
    .restaurant-wraper form label.col-form-label {
      flex: 0 0 13%;
      max-width: 13%;
    }
  }
  @media (min-width: 1081px) and (max-width: 1366px) {
    .restaurant-wraper form label.col-form-label {
      max-width: 25%;
      flex: 0 0 25%;
    }
    .restaurant-wraper h3.title-h3 {
      margin-right: 11.2%;
    }
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    .btn-qrcode {
      width: 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .btn-qrcode {
      width: 82%;
    }
    .restaurant-wraper form label.col-form-label {
      max-width: 29%;
      flex: 0 0 29%;
    }
  }
  @media (max-width: 1200px) {
    .restaurant-wraper h3.title-h3 {
      margin-right: 0;
    }
  }
  @media (max-width: 991px) {
    .restaurant-wraper h3.title-h3 {
      margin-right: 33px;
    }
  }
</style>
